import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';
import { Helmet } from 'react-helmet'; // Import react-helmet

import './style.css';
import Support from './views/support';
import Signup from './views/signup';
import TermsOfService from './views/terms-of-service';
import Business from './views/business';
import CashOverFees from './views/cash-over-fees';
import WorkWithUs from './views/work-with-us';
import Merchant from './views/merchant';
import Page from './views/page';
import CashOverSDK from './views/cash-over-sdk';
import Home from './views/home';
import Blog from './views/blog';
import FAQ from './views/faq';
import ScrollToTop from './utils/reset_scroll';

const App = () => {
  return (
    <Router>
      <ScrollToTop />
      <Helmet>
        <title>CashOver Pay | Ultimate Finance App</title>
        <meta name="description" content="CashOver Pay is the ultimate finance app providing secure payment solutions, wallet management, and financial tracking." />
        <link rel="canonical" href="https://cashover.money/" />
      </Helmet>
      <Switch>
        <Route path="/support" exact>
          <Helmet>
            <title>Support | CashOver Pay</title>
            <meta name="description" content="Get support and assistance for using CashOver Pay." />
          </Helmet>
          <Support />
        </Route>
        <Route path="/signup" exact>
          <Helmet>
            <title>Sign Up | CashOver Pay</title>
            <meta name="description" content="Create your CashOver Pay account today!" />
          </Helmet>
          <Signup />
        </Route>
        <Route path="/terms-of-service" exact>
          <Helmet>
            <title>Terms of Service | CashOver Pay</title>
            <meta name="description" content="Review the terms of service for CashOver Pay." />
          </Helmet>
          <TermsOfService />
        </Route>
        <Route path="/business" exact>
          <Helmet>
            <title>Business Solutions | CashOver Pay</title>
            <meta name="description" content="Explore business solutions with CashOver Pay." />
          </Helmet>
          <Business />
        </Route>
        <Route path="/cash-over-fees" exact>
          <Helmet>
            <title>CashOver Fees | CashOver Pay</title>
            <meta name="description" content="Learn about the fees associated with CashOver Pay." />
          </Helmet>
          <CashOverFees />
        </Route>
        <Route path="/work-with-us" exact>
          <Helmet>
            <title>Work With Us | CashOver Pay</title>
            <meta name="description" content="Join the CashOver Pay team and help us grow." />
          </Helmet>
          <WorkWithUs />
        </Route>
        <Route path="/merchant" exact>
          <Helmet>
            <title>Merchant Solutions | CashOver Pay</title>
            <meta name="description" content="Discover merchant solutions offered by CashOver Pay." />
          </Helmet>
          <Merchant />
        </Route>
        <Route path="/cash-over-sdk" exact>
          <Helmet>
            <title>CashOver SDK | CashOver Pay</title>
            <meta name="description" content="Integrate with CashOver Pay using our SDK." />
          </Helmet>
          <CashOverSDK />
        </Route>
        <Route path="/" exact>
          <Helmet>
            <title>Home | CashOver Pay</title>
            <meta name="description" content="Welcome to CashOver Pay, the ultimate finance app." />
          </Helmet>
          <Home />
        </Route>
        <Route path="/blog" exact>
          <Helmet>
            <title>Blog | CashOver Pay</title>
            <meta name="description" content="Read the latest updates and insights from CashOver Pay." />
          </Helmet>
          <Blog />
        </Route>
        <Route path="/faq" exact>
          <Helmet>
            <title>FAQ | CashOver Pay</title>
            <meta name="description" content="Find answers to frequently asked questions about CashOver Pay." />
          </Helmet>
          <FAQ />
        </Route>
        <Route path="**">
          <Helmet>
            <title>Page Not Found | CashOver Pay</title>
            <meta name="description" content="The page you are looking for does not exist." />
          </Helmet>
          <Page />
        </Route>
      </Switch>
    </Router>
  );
};

ReactDOM.render(<App />, document.getElementById('app'));
